<template>
    <ListPreauthorizations></ListPreauthorizations>
</template>
  
  <script>

import ListPreauthorizations from "@core/layouts/components/admin-components/ListPreauthorizations.vue";

export default {
    name: "Preauthorizations",

    components: {
        ListPreauthorizations,
    }
}
</script>
  
  <style scoped>
  </style>